<template>
    <div class="lock-details">
        <!-- 车系/车型/外饰/内饰 -->
        <div class="gray-font mb-8">
            <div class="mb-8">
                {{ seriesName }} |
                {{ modelName }} |
                {{ colourName }} |
                {{ interiorName }} 
            </div>
            <div>VIN: {{ vin || '--'  }}</div>
        </div>
        <div v-for="(item,idx) in list" :key="idx">
          <lock-item :info="item" :isDeliverManager="isDeliverManager"/>
        </div>
    </div>
</template>
<script>
import LockItem from '@/modules/deliverManage/component/lockItem.vue'
import deliveryServices from '@/services/deliveryServices.js'
import loading from '@/utils/loading'
import { mapGetters } from 'vuex'
export default {
  components: {
    LockItem,
  },
  data() {
    return {
      orderId: '',
      seriesName: '',
      modelName: '',
      colourName: '',
      interiorName: '',
      vin: '',
      list: []
    }
  },
  computed:{
    ...mapGetters(['userInfo']),
    isDeliverManager(){
      const flag = this.userInfo.roleLogos.includes('1014007')
      console.log('isDeliverManager:',flag)
      return flag
    }
  },
  created(){
    const { orderId,seriesName,modelName,colourName,interiorName,vin } = this.$route.query
    this.orderId = orderId
    this.seriesName = seriesName
    this.modelName = modelName
    this.colourName = colourName
    this.interiorName = interiorName
    this.vin = vin
    this.getListData()
  },
  methods: {
    // 获取列表数据
    async getListData() {
      loading.showLoading()
      // const res = await deliveryServices.lockList(this.orderId)
      const res = await deliveryServices.lockList(this.vin)
      this.list = res
      loading.hideLoading()
    },
  }

}
</script>
<style lang="less" scoped>
.lock-details{
  position: relative;
  padding: 16px;
  display: flex;
  flex-direction: column;
  color: #0D171A;
  font-size: 14px;
}
</style>