<template>
    <div class="lock-item">
        <div class="header">
            <div class="common-title">{{ info.lockType===1 ? $t('质损信息') : info.lockType===4 ? $t('Block信息') : $t('总部锁定') }}</div>
            <div :class="[`status_${info.lockStatus}`]">{{ info.lockStatus === 1 ? $t('已锁定'): $t('已解除') }}</div>
        </div>
        <div class="content">
            <div v-for="field in fieldList" :key="field.key" class="row">
                <span>{{ field.label }}：</span>
                <span v-if="field.isDict">{{ info[field.key] | codeFormat }}</span>
                <span v-else>{{ field.getValue || info[field.key] || '--' }}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'lockItem',
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    isDeliverManager: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      levelMap: {
        '20211001': 'A级',
        '20211002': 'B级',
        '20211003': 'C级'
      }
    }
  },
  computed: {
    fieldList(){
      // 根据类型展示不同的字段
      let result = []
      if (this.info.lockType===1){
        // d-cos
        result = [
          {
            label: '质损单号',
            key: 'qualityLossOrderId'
          },
          {
            label: '处理状态',
            key: 'qualityLossStatus',
            isDict: true
          },
          {
            label: '质损等级',
            key: 'qualityLossLevel',
            getValue: this.levelMap[this.info.qualityLossLevel] 
          }
        ]
        if (this.isDeliverManager){
          result.push({
            label: '问题性质',
            key: 'qualityLossType',
            isDict: true
          })
        }
        
        result.push({
          label: '更新时间',
          key: 'changeOrderTime'
        })
      } else if (this.info.lockType===2){
        // 总部
        result = [
          {
            label: '锁定原因',
            key: 'lockReason'
          }, 
          {
            label: '更新时间',
            key: 'changeOrderTime'
          }
        ]
      } else if (this.info.lockType===4){
        // 总部
        result = [
          {
            label: 'Block批次号',
            key: 'qualityLossOrderId'
          }, 
          {
            label: '冻结原因',
            key: 'lockReason'
          }, 
          {
            label: '冻结时间',
            key: 'orderCreateTime'
          }
        ]
        if (this.info.lockStatus === 0) {
          result.push({
            label: '解冻时间',
            key: 'changeOrderTime'
          })
        }
      }
      return result  
    }
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.lock-item {
    width: 100%;
    height: 100%;
    background: #fff;
		box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
		border-radius: 8px;
		padding: 12px;
    padding-top: 0;
		font-size: 14px;
    margin-top: 10px;
    .header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      /deep/.common-title{
        height: 36px;
        border-bottom: 0;
      }
      .status_1{
        color: @yellow;
      }
      .status_0{
        color: green;
      }
    }
    .row{
      line-height: 22px;
      word-break: break-all;
    }
}
</style>